import HeroUnit from "../../components/HeroUnit";
import BottomQuote from "./BottomQuote";
import CapsimAwards from "./CapsimAwards";
import CapsimNumbers from "./CapsimNumbers";
import Hero2 from "./Hero2";
import Vantagens from "./Vantagens";

export default function Home() {
  return (
    <>
      <div className="container">
        <HeroUnit
          title="Transformamos cultura e desenvolvemos competência ESSENCIAIS"
          action1="/way-in"
          action1Text="Acessar Simulação"
          img="/img/Escritorio.jpg"
        />

        <hr />
        <div className="m-5">&nbsp;</div>
        <div className="container text-center">
          <h4 className="m-3 display-4">
            Consolidamos conhecimentos gerando valores ÚNICOS
          </h4>
          <div className="m-4">
            <img
              src="/img/amigos.jpg"
              style={{ width: "40%", maxWidth: "1024px" }}
              alt=""
            />
            <img
              src="/img/amigos2.jpg"
              style={{ width: "40%", maxWidth: "1024px" }}
              alt=""
            />
          </div>
        </div>
        <div className="m-5">&nbsp;</div>
        <hr />
        <Hero2 />
        <div className="m-5">&nbsp;</div>
        <CapsimNumbers />
        <div className="m-5">&nbsp;</div>
        <CapsimAwards />
      </div>
      <Vantagens />
      <div className="m-5">&nbsp;</div>
      <BottomQuote />
      <div className="m-5">&nbsp;</div>
    </>
  );
}
