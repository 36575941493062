
export default function About() {
  return (
    <div className="container">
      <div className="px-4 py-5 my-5 text-center">
        <img
          className="d-block mx-auto mb-4"
          src="/img/sobre/colaboradores.jpg"
          alt=""
          width="90%"
        />
        <h1 className="display-5 fw-bold">
          Criando caminho para o aprendizado das PESSOAS
        </h1>
        <div className="col-lg-12 mx-auto">
          <p className="lead mb-4">
            Desde 2013, a CAPSIM é representada com exclusividade no Brasil
            pela COMPSIM Treinamento e Desenvolvimento Ltda. Inicialmente, os
            simuladores eram utilizados apenas em escolas de negócios e cursos
            de graduação nas áreas de negócios e administração. Os treinamentos  
            corporativo surgem para contribuir com executivos que buscam criar 
            valor a partir do processo de tomada de decisão. Em 2015, aproveitando a
            expansão do uso dos simuladores da CAPSIM, a COMPSIM começa a oferecer 
            soluções para gestores e líderes de diferentes segmentos consolidando
            modelos e conceitos relacionados ao processo de tomada de
            decisão. Desde então a empresa continua a desenvolver novas
            metodologias, simulações e avaliações para apoiar as necessidades de
            educação acadêmica e empresarial no BRASIL.
          </p>
        </div>
        <h1 className="display-5 fw-bold">
          nossa PROPOSTA de VALOR
        </h1>
        <div className="col-lg-12 mx-auto">
          <p className="lead mb-4">
            Contribuir de forma intensa com o desenvolvimento das pessoas
            por meio da inclusão, diversidade e da democratização do conhecimento. 
            Não medimos esforços para alcançar nossos objetivos e não negociamos nossos princípios.
            Apoiamos nossas ações na transparência, na cordialidade e na criação de valor
            para todos. Respeitamos nossos fundamentos teóricos e não negociamos nosso
            compromisso de ensinar e transformar. Quando atuamos na acadêmia consolidamos
            oportunidades para os futuros líderes, quando atuamos nas empresas contribuimos
            para o sucesso do negócio.
          </p>
        </div>

      </div>
    </div>
  );
}
