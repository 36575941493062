export default function CapsimAwards() {
  return (
    <div className="container text-center">
      <h4 className="m-4 display-5">
        Uma maneira comprovada de desenvolver LÍDERES que pensam no amanhã
      </h4>
      <div className="m-4">
        <img
          src="/img/Capsim-Awards_Banner_Band-1200px-tiny.png"
          style={{ width: "100%", maxWidth: "1024px" }}
          alt="Capsim Awards"
        />
      </div>
    </div>
  );
}
