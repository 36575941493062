import { ReactNode } from "react";
import Footer from "./Footer";
import Header from "./Header";

export default function Layout(props: { children?: ReactNode | ReactNode[] }) {
  const { children } = props;
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
}
