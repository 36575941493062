import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

export default function HeroUnit(props: {
  title?: ReactNode;
  subtitle?: ReactNode;
  img?: string;
  action1?: string;
  action1Text?: string;
  action2?: string;
  action2Text?: string;
}) {
  const { title, subtitle, img, action1, action1Text, action2, action2Text } =
    props;
  return (
    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
      <div className="col-10 col-sm-8 col-lg-6">
        <img
          src={img}
          className="d-block mx-lg-auto img-fluid"
          alt="Bootstrap Themes"
          loading="lazy"
          width="700"
          height="500"
        />
      </div>
      <div className="col-lg-6">
        <h1 className="display-5 fw-bold lh-1 mb-3">{title}</h1>
        <p className="lead">{subtitle}</p>
        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
          {action1 ? (
            <NavLink
              to={action1}
              className="btn btn-primary btn-lg px-4 me-md-2"
            >
              {action1Text}
            </NavLink>
          ) : null}
          {action2 ? (
            <NavLink
              to={action2}
              className="btn btn-outline-secondary btn-lg px-4"
            >
              {action2Text}
            </NavLink>
          ) : null}
        </div>
      </div>
    </div>
  );
}
