export default function ComoFunciona() {
  return (
    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
      <div className="col-10 col-sm-8 col-lg-6">
        <img
          src="/img/simulacoes/resultados.jpg"
          className="d-block mx-lg-auto img-fluid"
          alt="Como funciona"
          loading="lazy"
        />
      </div>
      <div className="col-lg-6">
        <div className="row row-cols-1 row-cols-sm-12 row-cols-md-12 row-cols-lg-12 g-12 py-5">
          <div className="col d-flex align-items-start">
            <div>
              <h3 className="fw-bold mb-0 fs-4">1. Diagnóstico</h3>
              <p>
                Os participantes iniciam o processo de tomada de decisão por
                meio da analise da indústria, dos mercados e da concorrência. Os
                relatórios gerados pelos simuladores são diferenciados e possuem
                características que permitem aperfeiçoar tomada de decisão e
                visão sistêmica.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <div>
              <h3 className="fw-bold mb-0 fs-4">2. Formulação da Estratégia</h3>
              <p>
                Os participantes usam as informações coletadas para formular uma
                estratégia de expansão e sustentabilidade dos negócios.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <div>
              <h3 className="fw-bold mb-0 fs-4">3. Execução</h3>
              <p>
                Os participantes executam decisões estratégicas nas principais
                funções gerenciais entre elas Pesquisa e Desenvolvimento,
                Marketing, Produção, Finanças, Recursos Humanos, Negociação
                Sindical e Qualidade.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
