export default function Maneiras() {
  return (
    <div className="container">
      <hr />
      <div className="px-4 pt-4 my-5 text-center">
        <h2 className="display-5 fw-bold">
          Como DESENVOLVEMOS nossas soluções?
        </h2>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">AVALIAÇÃO dos cenários</h5>
            </div>
            <div className="card-body">
              <p className="card-text">
                Usamos nosso portfólio de soluções com simulações e avaliações de
                negócios para criar programas de treinamento consistentes e de
                forte impacto no desenvolvimento das pessoas.
              </p>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Apoio aos PROFESSORES</h5>
            </div>
            <div className="card-body">
              <p className="card-text">
                Aos PROFESSORES que desejam aplicar nossos simuladores, oferecemos
                todo treinamento e suporte durante a disciplina e também contribuimos com 
                o desenvolvimento do material didático.
              </p>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Nossas PARCERIAS</h5>
            </div>
            <div className="card-body">
              <p className="card-text">
                Atuamos em empresas de diversos segmentos tamanhos.
                Contribuimos com a formação de gestores em diferentes momentos 
                de carreira e consolidamos visão sistêmica para o processo decisório.
                
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
