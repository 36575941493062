export default function Hero1() {
  return (
    <div className="container">
      <div className="col-xxl-12 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <ul className="list-group">
              <li className="list-group-item list-group-item-primary display-6">
                Comunicação{" "}
              </li>
              <li className="list-group-item list-group-item-success display-6">
                Solução de problemas{" "}
              </li>
              <li className="list-group-item list-group-item-info display-6 ">
                Pensamento estratégico{" "}
              </li>
              <li className="list-group-item list-group-item-warning display-6">
                Visão de negócios{" "}
              </li>
              <li className="list-group-item list-group-item-secondary display-6">
                Desenvolvimento de liderança{" "}
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <h1 className="display-5 fw-bold lh-1 mb-3">
              HABILIDADES essenciais 
              {" "}
            </h1>
            <p className="lead">
              Criamos experiências de aprendizado de alto impacto baseadas em
              simulação de negócios, oferecendo aos colaboradores um ambiente de
              tomada de decisão sem risco onde as habilidades são aprimoradas
              para enfrentar os desafios do dia a dia corporativo.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
