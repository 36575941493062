import { NavLink } from "react-router-dom";

export default function CapsimCore() {
  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/simulacoes">Simulações</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Capsim Core
          </li>
        </ol>
      </nav>

      <h2>Descrição</h2>

      <p>
        O CapsimCore é uma simulação de negócios simples e fácil de usar,
        voltado para cursos básicos de gestão. Aprimora os conceitos
        fundamentais de negócios e é o ponto de partida ideal para a educação
        empresarial aos participantes.
      </p>

      <h2>Cenário</h2>

      <p>
        Os participantes assumem uma empresa que fabrica sensores – dispositivos
        encontrados em celulares, veículos e muito mais. A empresa foi criada
        quando o governo quebrou um monopólio dando origem a seis concorrentes
        idênticos. Com o aumento da concorrência, cabe aos participantes
        melhorar seus produtos, definir novas estratégias de marketing, produção
        e finanças para ganhar participação de mercado e gerar valor para os
        stakeholders.
      </p>

      <h2>Decisões</h2>

      <p>
        Os participante tomam decisões nos seguintes departamentos:
        <ul>
          <li>Pesquisa e Desenvolvimento</li>
          <li>Marketing</li>
          <li>Produção</li>
          <li>Finanças</li>
        </ul>
        <h2>Performance</h2>
      </p>
      <p>
        O CapsimCore usa um placar com 5 estrelas para medir o desempenho das
        empresas. A pontuação ocorre quando são implementadas melhorias em cinco
        indicadores de desempenho (KPIs) a cada rodada. Esse modelo é
        simplificado e permite o desenvolvimento de estratégias de crescimento
        incremental e sustentável.
      </p>
      <p>
        Os participantes são medidos nos seguintes KPIs:
        <ul>
          <li>Receita</li>
          <li>Lucro</li>
          <li>Margem de contribuição</li>
          <li>Preço de Ações</li>
          <li>Falta de Empréstimo de Emergência</li>
        </ul>
      </p>
    </div>
  );
}
