import { NavLink } from "react-router-dom";

export default function capsimops() {
  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/simulacoes">Simulações</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            CAPSIMOPS
          </li>
        </ol>
      </nav>

      <h2>Descrição</h2>

      <p>
        O CAPSIMOPS é uma simulação de negócios voltada para operações,
        em cursos de engenharia e negócios. Aprimora os conceitos
        de custos e é ideal para o desenvolvimento da primeira gerência
        que busca na produção entender perspectivas de geração de valor.
      </p>

      <h2>Cenário</h2>

      <p>
        Os participantes assumem uma empresa que fabrica sensores – dispositivos
        encontrados em celulares, veículos e muito mais. A empresa foi criada
        quando o governo quebrou um monopólio dando origem a seis concorrentes
        idênticos. Com o aumento da concorrência, cabe aos participantes
        melhorar seus produtos, definir novas estratégias de marketing, produção
        recursos humanos, qualidade total e finanças para ganhar participação de 
        mercado e gerar valor para os stakeholders.
      </p>

      <h2>Decisões</h2>

      <p>
        Os participante tomam decisões nos seguintes departamentos:
        <ul>
          <li>Pesquisa e Desenvolvimento</li>
          <li>Marketing</li>
          <li>Produção</li>
          <li>Recursos Humanos</li>
          <li>Qualidade Total</li>
          <li>Negociação Sindical</li>
          <li>Finanças</li>
        </ul>
        <h2>Performance</h2>
      </p>
      <p>
        O CAPSTONE usa métricas definidas pelo BSC para medir o desempenho das
        empresas. Os indicadores de desempenho refletem as ações gerenciais e 
        a cada rodada. Esse modelo permite aprofundar o desenvolvimento das 
        estratégias preparando os profissionais para tomarem decisões consitentes
        e sustentáveis no mundo real.
      </p>
      <p>
        Os participantes são medidos por meio de indicadores de desempenho do BSC:
        <ul>
          <li>Perspectiva Financeira </li>
          <li>Perspectiva de Operações</li>
          <li>Perspectiva de Clientes</li>
          <li>Perspectiva de Crescimento</li>
        </ul>
      </p>
    </div>
  );
}
