export default function Terms() {
  return (
    <div className="container">
      <div className="page-header">
        <h1>Termos e Condições de Uso</h1>
      </div>
      <p>INTRODUÇÃO</p>
      <p>
        Obrigado por acessar o site da CAPSIM Affiliate Brasil! Aqui buscamos 
        criar experiências de aprendizado. Queremos que todos que acessem nosso 
        site se sintam especiais. Nós da COMPSIM, representantes oficiais da CAPSIM 
        no Brasil, colocamos em prática nossos princípios, nossas prioridades e 
        nossa missão na busca do desenvolvimento do conhecimento. 
        Através deste documento (“Termos de Uso” ou “Termos”), você terá acesso 
        a informações gerais sobre a sua
        interação com a plataforma da CAPSIM (“Plataforma”) e a utilização dos
        serviços disponibilizados pela CAPSIM no Brasil. Recomendamos que você leia
        atentamente estes Termos de Uso.
      </p>
      <p>
        1.1. É preciso que você siga nossos Termos de Uso e conheça nossa
        Política de Privacidade para navegar na Plataforma da COMPSIM e utilizar
        nossas Soluções, tendo em vista que essas atividades implicam no
        tratamento dos seus dados pessoais..
      </p>
      <p>
        &nbsp;&nbsp;1.1.1 A Política de Privacidade estabelece informações sobre
        as condições em que a COMPSIM realiza o tratamento dos seus dados
        pessoais durante o oferecimento da sua Plataforma, Produtos e dos
        Serviços, bem como informações sobre os seus direitos enquanto titular
        desses dados.
      </p>
      <p>
        &nbsp;&nbsp;1.1.2. A Política de Privacidade também integra estes Termos
        de Uso e deverá ser lida com atenção e consentida por você como
        requisito para a utilização da Plataforma, Produtos e dos Serviços da
        COMPSIM.
      </p>
      <p>
        &nbsp;&nbsp;1.1.3. Estes Termos se aplicam a todas as marcas, produtos e
        serviços da COMPSIM, os quais não têm políticas de privacidade separadas
        e estão vinculados a esta Política.
      </p>
      <p>
        1.2. Os Produtos e Serviços oferecidos pela CAPSIM no Brasil são fornecidos pela
        COMPSIM Treinamento Ltda., CNPJ nº 10.613.397/0001-40, titular da
        propriedade intelectual sobre website, aplicativos, conteúdos
        e demais ativos digitais ou não, relacionados à Plataforma, Produtos e
        aos Serviços da COMPSIM. Os direitos autorais dos simuladores de negócios
        são da Capsim Management Simulations, Inc., empresa com sede em 2012 Corporate Lane, 
        Suite 108, Naperville, IL 60563, USA.
      </p>
      <p>
        1.3. A COMPSIM é uma empresa que visa licenciar o uso de software,
        website, aplicativos, conteúdos e demais ativos de propriedade
        intelectual, fornecendo soluções para o desenvolvimento de profissionais
        e alunos em ambientes corporativos e educacionais, facilitando e
        consolidando oportunidades de aprendizado.
      </p>
      <p>
        1.4. Os presentes Termos de Uso estabelecem obrigações contratadas de
        livre e espontânea vontade, por tempo indeterminado, entre a COMPSIM e
        as pessoas físicas ou jurídicas, assinantes ou não assinantes, que
        utilizarem ou visitarem a Plataforma da CAPSIM (“Usuários”).
      </p>
      <p>
        1.5. A aceitação do presente instrumento é imprescindível para o acesso
        à Plataforma da CAPSIM e para a utilização dos seus Produtos e
        Serviços. Caso não concorde com as disposições destes Termos, o Usuário
        não deve acessá-la ou utilizá-los. Além disso, o Usuário não poderá se
        escusar dos Termos, alegando ignorância sobre as suas condições,
        inclusive quanto a eventuais modificações nas suas disposições.
      </p>
      <p>DIREITO DE PROPRIEDADE INTELECTUAL</p>
      <p>
        Em nenhuma hipótese haverá transferência de qualquer direito de
        propriedade intelectual no âmbito deste contrato de Termos e Condições
        de Uso. Todos os direitos de proriedade dos Produtos e Serviços deste
        site são da COMPSIM Treinamentos Ltda.
      </p>
      <p>PRODUTOS E SERVIÇOS</p>
      <p>
        Os Produtos e Serviços deste site são acessados a partir de licença que
        deve ser utilizada em um prazo máximo de 6 meses a contar da data de
        aquisição.
      </p>
      <p>
        O acesso aos Produtos e Serviços desenvolvidos pela CAPSIM são
        individuais e intransferíveis. É proibido o compartilhamento de senhas e
        acessos aos Produtos e Serviços.
      </p>
      <p>FORO</p>
      <p>
        Fica estabelecido o Foro da Cidade de São Paulo - SP como o único
        competente para esclarecer as dúvidas ou controvérsias decorrentes deste
        contrato de Termos e Condições de Uso.
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Data de Publicação:
        09/03/2023 - Data da última Revisão: 09/03/2023
      </p>
    </div>
  );
}
