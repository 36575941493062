import { NavLink } from "react-router-dom";
import { scrollUp } from "../utils/ui";

interface INav {
  href: string;
  text: string;
}

const NAVS: INav[] = [
  {
    href: "/simulacoes",
    text: "Produtos",
  },
  {
    href: "/corporativo",
    text: "Corporativo",
  },
  {
    href: "/recursos",
    text: "Recursos",
  },
  {
    href: "/about",
    text: "Sobre a Capsim Brasil",
  },
];

function renderLink(nav: INav) {
  return (
    <NavLink
      key={nav.text}
      className="me-3 py-2 text-dark text-decoration-none"
      to={nav.href}
      onClick={scrollUp}
    >
      {nav.text}
    </NavLink>
  );
}

export default function Header() {
  return (
    <div style={{ height: "110px" }}>
      <div className="fixed-top" style={{ backgroundColor: "#fff" }}>
        <header className="container">
          <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom pr-3">
            <NavLink
              to="/"
              className="d-flex align-items-center text-dark text-decoration-none"
            >
              <img src="/img/capsim-affiliate-sm.jpg" alt="Capsim Affiliate" />
            </NavLink>
            <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
              {NAVS.map(renderLink)}
            </nav>
          </div>
        </header>
      </div>
    </div>
  );
}
