export default function Features() {
  return (
    <div className="container">
      <hr />
      <div className="px-4 pt-4 my-5 text-center border-bottom">
        <h2 className="display-5 fw-bold">
          Personalizamos o aprendizado e o DESENVOLVIMENTO
        </h2>
        <div className="m-4">
          <p className="lead">
            Fazemos parceria com você para entender as metas de sua organização
            e criamos um programa para abordar com eficácia seus MAIORES desafios
            de APRENDIZADO e desenvolvimento
          </p>
        </div>
      </div>
      <div className="row g-4 py-5 row-cols-1 row-cols-lg-5">
        <div className="feature col">
          <h3 className="fs-2">1. Necessidades</h3>
          <p>Começamos por entender sua empresa, metas e desafios.</p>
        </div>
        <div className="feature col">
          <h3 className="fs-2">2. Habilidades</h3>
          <p>
            Usamos nossas avaliações baseadas em simulação para medir as
            habilidades mais importantes.
          </p>
        </div>
        <div className="feature col">
          <h3 className="fs-2">3. Construção</h3>
          <p>
            Utilizamos dados das habilidades demonstradas 
            para projetar um programa sob medida.
          </p>
        </div>
        <div className="feature col">
          <h3 className="fs-2">4. Alinhamento</h3>
          <p>
            Implementamos um programa de aprendizado baseado na experiência para
            desenvolver as habilidades direcionadas.
          </p>
        </div>
        <div className="feature col">
          <h3 className="fs-2">5. Repensar</h3>
          <p>
            Reavaliamos as habilidades para comprovar o crescimento dos
            funcionários e a eficácia do programa.
          </p>
        </div>
      </div>
    </div>
  );
}
