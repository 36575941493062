export default function Microsimuladores() {
  return (
    <div className="container">
      <div className="container col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img
              src="bootstrap-themes.png"
              className="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              loading="lazy"
              width="700"
              height="500"
            />
          </div>
          <div className="col-lg-6">
            <h1 className="display-5 fw-bold lh-1 mb-3">Microsimuladores</h1>
            <p className="lead">
              Os microsimuladores desafiam os participantes nas principais áreas
              da gestão durante o processo de tomada de decisão. Avaliando as
              principais habilidades e competências necessárias para as novas
              dinâmicas empresariais, o simulador pode ser realizado em 30
              minutos com avaliação imediata e compatível com os objetivos
              definidos em cada cenário corporativo.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
