export default function Vantagens() {
  return (
    <div style={{ backgroundColor: "#6f477a" }}>
      <div className="container text-center text-white">
        <div className="px-4 pt-5 my-5">
          <h2 className="display-6 fw-bold">
            SIMPLIFICAMOS a experiência do aprendizado
          </h2>
        </div>
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-4">
          <div className="feature col">
            <div className="feature-iconfs-2 mb-3">
              <img src="/img/personalized.png" alt="" />
            </div>
            <h6>Treinamentos e soluções PERSONALIZADAS</h6>
          </div>
          <div className="feature col">
            <div className="feature-iconfs-2 mb-3">
              <img src="/img/syllabus.png" alt="" />
            </div>
            <h6>CONTEÚDOS atuais baseados nos desafios corporativos</h6>
          </div>
          <div className="feature col">
            <div className="feature-iconfs-2 mb-3">
              <img src="/img/faq.png" alt="" />
            </div>
            <h6>Suporte INTENSIVO</h6>
          </div>
          <div className="feature col">
            <div className="feature-iconfs-2 mb-3">
              <img src="/img/learning.png" alt="" />
            </div>
            <h6>Soluções baseadas na WEB e de fácil acesso</h6>
          </div>
        </div>
      </div>
    </div>
  );
}
