import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import About from "./about";
import Assessments from "./assessments";
import Docs from "./docs";
import CapsimCore from "./capsim-core";
import CapsimCapstone from "./capsim-capstone";
import CapsimGlobal from "./capsim-global";
import CapsimOPS from "./capsim-ops";
import Corporativo from "./corporativo";
import Home from "./home";
import Microsimuladores from "./microsimuladores";
import Recursos from "./recursos";
import Simulacoes from "./simulacoes";
import WayIn from "./way-in";

export default function Pages() {
  return (
    <BrowserRouter>
      <Header />
      <Suspense>
        <Routes>
          <Route path="/way-in" element={<WayIn />} />
          <Route path="/simulacoes" element={<Simulacoes />} />
          <Route path="/recursos" element={<Recursos />} />
          <Route path="/microsimuladores" element={<Microsimuladores />} />
          <Route path="/corporativo" element={<Corporativo />} />
          <Route path="/capsim-core" element={<CapsimCore />} />
          <Route path="/capsim-capstone" element={<CapsimCapstone />} />
          <Route path="/capsim-global" element={<CapsimGlobal />} />
          <Route path="/capsim-ops" element={<CapsimOPS />} />
          <Route path="/about" element={<About />} />
          <Route path="/assessments" element={<Assessments />} />
          <Route path="docs/*" element={<Docs />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
}
