export default function BottomQuote() {
  return (
    <div className="container">
      <div className="m-5">
        <h4 className="display-6 text-center">
          “As soluções desenvolvidas pala CAPSIM facilitam o aprendizado e
          consolidam visão sistêmica para aplicação do conhecimento na prática
          profissional dos tomadores de decisão!”
        </h4>
      </div>
    </div>
  );
}
