import { Link } from "react-router-dom";

export default function WayIn() {
  return (
    <div className="container">
      <div className="px-4 pt-5 my-5 text-center">
        <h2 className="display-6 fw-bold">Como participar</h2>
      
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <div className="feature col">
            <h3 className="fs-2">Acesso ao simulador</h3>
            <p>
              Para acessar os simuladores com seu usuário e senha,
              utilize o link.
            </p>
            <Link
              to="https://ww2.capsim.com/login/"
              target="_blank"
              className="btn btn-primary"
            >
              Simulador
            </Link>
          </div>
          <div className="feature col">
            <h3 className="fs-2">DEMO</h3>
            <p>
              Para solicitar uma DEMO,
              aproveite o link abaixo e nos mande um e-mail.
            </p>
            <Link
              to="mailto:marcelo.carbonari@capsim.com.br"
              className="btn btn-primary"
            >
              Demonstração
            </Link>
          </div>
          
        </div>
      </div>
    </div>
  );
}
