export function scrollUp() {
  window.setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 100);
}

export function goUp() {
  window.setTimeout(() =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  );
}
