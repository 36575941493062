import HeroUnit from "../../components/HeroUnit";
import ComoFunciona from "./ComoFunciona";
import Detalhe from "./Detalhe";
import Hero2 from "./Hero2";

export default function Simulacoes() {
  return (
    <div className="container">
      <HeroUnit
        title="Simuladores de NEGÓCIOS"
        subtitle="Proporciona aos participantes uma experiência real em um ambiente sistêmico, envolvente e competitivo. "
        img="/img/simulacoes/reuniao.jpg"
      />
      <div className="m-5">&nbsp;</div>
      <Hero2 />
      <ComoFunciona />
      <Detalhe />

    </div>
  );
}
