import { NavLink } from "react-router-dom";
import { goUp } from "../utils/ui";


export default function Footer() {
  return (
    <footer className="text-dark">
      <div className="container">
        <p className="float-end">
          <button className="btn" onClick={goUp}>
            Voltar ao Topo &#x2191;
          </button>
        </p>
        <div className="row">
          <div className="col-md-3">
            <h6 className="footer-header">CAPSIM Affiliate Brasil</h6>
            <ul className="list-unstyled">
              <li>COMPSIM Treinamento Ltda.</li>
              <li>CNPJ nº 10.613.397/0001-40</li>
              <li>
                <a href="mailto:suporte@compsim.com.br">
                  suporte@compsim.com.br
                </a>
              </li>
              <li>+55 (11) 99161-1969</li>
            </ul>
          </div>
          <div className="col-md-3">
            <h6 className="footer-header">Institucional</h6>
            <ul className="list-unstyled">
              <li>
                <NavLink
                  className="link-secondary"
                  to="/docs/privacy"
                  onClick={goUp}
                >
                  Privacidade
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="link-secondary"
                  to="/docs/terms"
                  onClick={goUp}
                >
                  Termos de Uso
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="link-secondary"
                  to="/docs/credits"
                  onClick={goUp}
                >
                  Créditos das Imagens
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h6 className="footer-header">Direitos</h6>
            <ul className="list-unstyled">
              <li>Copyright © 2023 COMPSIM Treinamento Ltda</li>
            </ul>
          </div>

        </div>
      </div>
    </footer>
  );
}
