import Features from "./Features";
import Hero1 from "./Hero1";
import Maneiras from "./Maneiras";

export default function Corporativo() {
  return (
    <>
      <Hero1 />
      <Features />

      <Maneiras />
    </>
  );
}
