export default function CapsimNumbers() {
  return (
    <div className="px-4 pt-5 my-5 text-center">
      <h2 className="display-6 fw-bold">A experiência CAPSIM em números no BRASIL</h2>
      <div className="row g-4 py-5 row-cols-1 row-cols-lg-4">
        <div className="feature col">
          <h3 className="fs-2">Estamos presentes nas principais escolas de negócios</h3>
        </div>
        <div className="feature col">
          <h3 className="fs-2">Empresas de diferentes segmentos</h3>
        </div>
        <div className="feature col">
          <h3 className="fs-2">Passaram por nós mais de 10.000 participantes</h3>
        </div>
        <div className="feature col">
          <h3 className="fs-2">Atuamos a mais de 15 anos com soluções inovadoras</h3>
        </div>
      </div>
    </div>
  );
}
