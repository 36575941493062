export default function Ebooks() {
  return (
    <div className="px-4 pt-4 my-5 text-center border-bottom">
      <h2 className="display-5 fw-bold">
        Nossos EBOOKS podem ser baixados para consolidar os modelos trabalhados nas simulações
      </h2>
      <div className="m-4">
        <p className="lead">
          Frequentemente elaboramos materiais para apoio ao processo de aprendizagem,
          gerando conhecimento para a comunidade que utiliza os nossos simuladores.
          Materiais com temas diversificados e base científica consistente nas áreas
          de estratégia, gestão, recursos humanos, operações e finanças.
          Baixe os nossos EBOOKS e consolide ainda mais as oportunidades geradas em
          nossos treinamentos.
        </p>
      </div>
    </div>
  );
}
