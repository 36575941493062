export default function Hero2() {
  return (
    <div className="px-4 pt-4 my-5 text-center border-bottom">
      <h2 className="display-5 fw-bold">
        Processo consistente de aprendizado em GESTÃO
      </h2>
      <div className="m-4">
        <p className="lead">
          Nossas simulações de negócios colocam os participantes no papel de
          gerência executiva em uma empresa que atua em um mercado altamente
          competitivo. Competindo diretamente contra outras equipes reais ou
          simuladas, os participantes desenvolvem estratégias para conquistar
          participação de mercado e criar valor para seus stakeholders.
        </p>
      </div>
    </div>
  );
}
