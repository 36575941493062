export default function Privacy() {
  return (
    <div className="container">
      <div className="page-header">
        <h1>Política de Privacidade</h1>
      </div>
      <p>
        Sua privacidade é fundamental e importante para nós. Esta política de
        privacidade explica os processos de dados pessoais da COMPSIM Treinamento
        Ltda, como a COMPSIM os processa e para que fins.
      </p>
      <p>
        A COMPSIM é a detentora dos direitos de representação da CAPSIM no Brasil
        e oferece uma ampla variedade de produtos e serviços, incluindo
        soluções educacionais e corporativas usadas para difundir conhecimento
        nos mais diferentes cenários, objetivos e necessidades. As referências
        aos produtos desenvolvidos pela CAPSIM nesta instrução incluem sites,
        aplicativos e simuladores.
      </p>
      <p>
        Leia os detalhes específicos dos produtos nesta política de privacidade,
        a qual fornece informações adicionais relevantes. Essa política se
        aplica às interações da COMPSIM com você e aos produtos e serviços
        listados abaixo, além de outros produtos que exibem esta política.
      </p>
      <p>DADOS PESSOAIS QUE COLETAMOS</p>
      <p>
        A COMPSIM coleta dados de nossos usuários, por meio de nossas interações
        e de nossos produtos. O fornecimento de alguns desses dados é feito
        diretamente, enquanto outros são obtidos durante coleta de dados, uso e
        experiências com nossos produtos e serviços. Os dados que coletamos
        dependem do contexto de suas interações com a COMPSIM e as opções que
        você determina, incluindo as suas configurações de privacidade e os
        produtos e recursos que você usa.
      </p>
      <p>
        Existem opções quando se trata do produto ou serviço que você usa e os
        dados que compartilha. Quando pedimos que forneça os seus dados
        pessoais, você pode recusar. Muitos dos nossos produtos exigem alguns
        dados pessoais para que o serviço seja oferecido. Caso você opte por não
        disponibilizar os dados necessários para o fornecimento de um produto ou
        recurso, você não poderá usar tal produto ou recurso.
      </p>
      <p>COOKIES</p>
      <p>
        Cookies são arquivos de texto pequenos colocados em seu dispositivo para
        armazenar dados que podem ser lembrados por um servidor Web no domínio
        que colocou o cookie. Nós usamos cookies e tecnologias semelhantes para
        armazenar e respeitar suas preferências e configurações, permitir que
        você faça login, fornecer publicidade baseada em interesses, combater
        fraudes, analisar o desempenho de nossos produtos e atender a outras
        finalidades legítimas. Os aplicativos da COMPSIM usam identificadores
        adicionais como ID de anúncio no Windows descrito na seção ID de anúncio
        desta política de privacidade para finalidades semelhantes.
      </p>
      <p>
        Você tem uma variedade de ferramentas para controlar os dados coletados
        por cookies e tecnologias semelhantes. Por exemplo, você pode usar os
        controles no seu navegador da Internet para limitar como os sites que
        você visita podem usar cookies e retirar o seu consentimento limpando ou
        bloqueando os cookies.
      </p>
    </div>
  );
}
