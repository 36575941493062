import HeroUnit from "../../components/HeroUnit";
import Ebooks from "./Ebooks";


export default function Recursos() {
  return (
    <div className="container">
      <HeroUnit
        title="The Capsim CHALLENGE"
        subtitle="O desafio Global entre os estudantes do simulador CAPSTONE. "
        img="/img/simulacoes/competição.jpg"
      />
      <Ebooks />
    </div>
  );
}
