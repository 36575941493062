export default function ComoFunciona() {
  return (
    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
      <h2 className="display-5 fw-bold text-center">
        Simulações para cada ESTÁGIO acadêmico e profissional
      </h2>
      <div className="col-1 col-sm-8 col-lg-12">
        <img
          src="/img/simulacoes/Simuladores.png"
          className="d-block mx-lg-auto img-fluid"
          alt="Como funciona"
          loading="lazy"
        />
      </div>
    </div>
  );
}
